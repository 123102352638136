
    import { computed, defineComponent, ref } from 'vue';
    import { Icon } from '@iconify/vue';
    import { useStore } from '@/store';
    import { SHOW_PRODUCTS } from '@/store/type-actions';
    
    
    export default defineComponent({
        name: "ModalSearchBar",
        components: {
            Icon,
        },
        props: ['close', 'show',],
       
        data() {
            return {
                env: process.env.VUE_APP_API_URL,
            }
        },
    
        setup() {
            const filter = ref('')
    
            const store = useStore();
            store.dispatch(SHOW_PRODUCTS);
    
            const products = computed(() =>
            store.state.products.filter(
                (p) => !filter.value || p.name_product.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase())
            ))
            return {
                filter,
                products
            }
        }
    })
    