
import { defineComponent } from 'vue';
import { Icon } from '@iconify/vue';
import { brazilianStates } from '../helpers/brazilianStates'
import { DEFINE_ADDRESSES } from '../store/type-mutations'
import { GET_ADDRESSES, NEW_ADDRESS, GET_ADDRESS_VIA_CEP } from '../store/type-actions'
import { typesNotification } from '@/interfaces/INotification';
import { NOTIFY } from '@/store/type-mutations';
import { useStore } from '@/store';

export default defineComponent({
  name: 'ModalAddress',

  components: {
    Icon,
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },

    userId: {
      type: String
    }
  },

  data() {
    return {
      states: brazilianStates,
      city: '',
      state: '',
      cep: '',
      modalState: 'normal',
      address: [
        {
          id: 'Addressneighborhood',
          label: 'Bairro',
          placeholder: 'Bairro',
          value: ''
        },
        {
          id: 'Addressstreet',
          label: 'Rua',
          placeholder: 'Rua',
          value: ''
        },
        {
          id: 'AddressNumber',
          label: 'Número',
          placeholder: 'Número',
          value: ''
        },
        {
          id: 'AddressComplement',
          label: 'Complemento',
          placeholder: 'Complemento',
          value: ''
        },
      ],
      userAddress: {}
    }
  },

  async mounted() {
    const parsedData = JSON.parse(JSON.stringify(this.data))
    this.cep = parsedData.CEP
    this.state = this.states.filter((state: {state: string, acronym: string}) => state.state.toLowerCase() == parsedData.NomeEstado.toLowerCase())[0].acronym
    this.city = parsedData?.NomeCidade
    this.address[0].value = parsedData?.Bairro
    this.address[1].value = parsedData?.Endereco
    this.address[2].value = parsedData?.Numero.toString()
    this.address[3].value = parsedData?.Complemento

    await this.store.dispatch(GET_ADDRESSES, this.loginId)
    this.userAddress = this.store.state.userAddresses
  },

  methods: {
    newAddress() {
      try {
        this.store.dispatch(NEW_ADDRESS, {
        id_client: this.userId,
        zip_code: this.cep,
        street: this.address[1].value,
        number: this.address[2].value,
        neighborhood: this.address[0].value,
        city: this.city,
        state: this.state,
        complement: this.address[3].value
        }).then(async () => {
          this.store.commit(NOTIFY, {
            title: 'Sucesso!',
            text: `Endereço cadastrado com sucesso.`,
            type: typesNotification.CART,
          });
          await this.store.dispatch(GET_ADDRESSES, this.loginId)
          this.userAddress = this.store.state.userAddresses
        })

      }catch(err) {
        console.log(err)
      }

    },

    async getAddressViaCep(cep: string) {
      if (cep.length >= 9) {

        const address = await this.store.dispatch(GET_ADDRESS_VIA_CEP, cep.replace('-','')).then((res) => res)
        this.address[1].value = address.logradouro
        this.address[0].value = address.bairro
        this.address[3].value = address.complemento
        this.city = address.localidade
        this.state = address.uf
  
      }
    }
   },

  setup() {
    const store = useStore();
    const loginId = store.state.login.data.id

    return {
      store,
      loginId
    }
  }
});

