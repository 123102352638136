export const DEFINE_PRODUCTS = 'DEFINE_PRODUCTS';
export const DEFINE_SIZES = 'DEFINE_SIZES';
export const DEFINE_OPTIONS = 'DEFINE_OPTIONS';
export const NOTIFY = 'NOTIFY';
export const PIX_QR_CODE = 'PIX_QR_CODE';
export const DEFINE_SUCCESS_BUY = 'DEFINE_SUCCESS_BUY';
export const DEFINE_USER = 'DEFINE_USER';
export const DEFINE_LOGIN = 'DEFINE_LOGIN';
export const DEFINE_SLIDES = 'DEFINE_SLIDES';
export const DEFINE_PRODUCT = 'DEFINE_PRODUCT';
export const DEFINE_ADDRESSES = 'DEFINE_ADDRESSES';
