<template>
  <input :id="id" v-maska :data-maska="dataMaska" :placeholder="placeholder"/>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputEnerzee",

  props: {
    id: {
      type: String
    },
    placeholder: {
      type: String
    },
    dataMaska: {
      type: String
    }
  }
});


</script>
