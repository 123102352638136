import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed z-50 xs:self-center md:self-start mt-20 md:ml-20"
}
const _hoisted_2 = { class: "bg-white p-1 rounded" }
const _hoisted_3 = { class: "text-xl break-words text-white uppercase font-semibold" }
const _hoisted_4 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "notification",
    class: "z-50"
  }, {
    default: _withCtx(() => [
      (_ctx.notifications.length === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([_ctx.contextBg[notification.type], "flex items-center gap-2 p-2 rounded w-80 lg:w-96"]),
                key: notification.id
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_Icon, {
                    icon: _ctx.contextIcon[notification.type],
                    class: _normalizeClass(["text-5xl", _ctx.contextIconColor[notification.type]])
                  }, null, 8, ["icon", "class"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(notification.title), 1),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(notification.text), 1)
                ])
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}