import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Cart from '../views/CartPage.vue';
import Product from '../views/Product.vue';
import NotFound from '../views/NotFound.vue';
import Auth from '../views/Auth.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:user',
    name: 'auth',
    component: Auth,
    props: true,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
  },
  {
    path: '/products/:id',
    name: 'view product',
    component: Product,
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

export default router;
