
import { defineComponent } from 'vue';

export default defineComponent({
    name: "EnerzeeAuth",

    props: {
        user: {
        type: String,
    },
    },

     mounted() {
        localStorage.setItem('login', this.user || '')
        this.$router.push('/')
        .then(() => location.reload())
    },

  
})
