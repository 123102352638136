
import { computed, defineComponent } from 'vue';
import { Icon } from '@iconify/vue';
import { db } from '@/firebase';
import { deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useStore } from '@/store';
import { GET_PRODUCT } from '../store/type-actions';

export default defineComponent({
  name: 'DisplayProductsCart',
  components: {
    Icon,
  },
  emits: ['close'],
  props: {
    cartItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
      firebase: process.env.VUE_APP_FIREBASE_STORE || 'CartItemsHomologacao',
    };
  },
  methods: {
    async changeQuantity(product: any, type: 'plus' | 'minus') {
       const checkDoc = await getDoc(doc(db, this.firebase + this.login.data.id, product.product_id + product.color + product.size));
       const getPrice = (!isNaN(this.product.price) && this.product.price != 0  ? this.product.price : this.product.price_promotion); 
       let newQuantity = type == 'plus' ? checkDoc.data()?.quantity + 1 : checkDoc.data()?.quantity - 1;

      if (checkDoc.data()?.quantity <= 1 && type == 'minus') {
        newQuantity = 1;
      }

      if (checkDoc.data()?.quantity >= parseInt(this.product.option_product?.filter((opt: any) => opt.id == this.product.product_option)[0]?.option[0]?.quantity) && type == 'plus') {
        newQuantity = parseInt(this.product.option_product?.filter((opt: any) => opt.id == this.product.product_option)[0]?.option[0]?.quantity);
      }

      updateDoc(doc(db, this.firebase + this.login.data.id, product.product_id + product.color + product.size), {
        quantity: newQuantity,
        price: newQuantity * getPrice,
      });
    },
    removeCartItem(product: any) {
      deleteDoc(doc(db, this.firebase + this.login.data.id, product.product_id + product.color + product.size));
    },
  },
  async mounted() { 
    await this.store.dispatch(GET_PRODUCT, this.cartItem.product_id);
  },
  setup() {
    const store = useStore();
    return {
      login: computed(() => store.state.login),
      product: computed(() => store.state.product),
      store,
    };
  },
});
