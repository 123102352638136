import axios, { AxiosInstance } from "axios";



  async function login() {
     await axios
      .post(process.env.VUE_APP_API_URL + "api/user", {
        email: process.env.VUE_APP_TOKEN_LOGIN,
        password: process.env.VUE_APP_TOKEN_PASSWORD,
      })
      .then((response) => response.data.token)
      .then((data) => {
        localStorage.setItem("user", data)
      })
      .catch((error: any) => console.log(error));
  }

 await login();

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user")}`,
            "Content-type": "application/json",
          },
});


export default httpClient 
