
import { defineComponent } from 'vue';
import ModalPix from './ModalPix.vue';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'ModalPayments',

  components: {
    ModalPix,
    Icon,
  },

  props: ['loading', 'show', 'close', 'pixData', 'regenerate', 'restartingQR'],
});
