import { IProducts } from '@/interfaces/IProduct';
import { InjectionKey } from 'vue';
import { Store, createStore, useStore as vuexUseStore } from 'vuex';
import http from '@/http';
import {
  GET_USERS,
  NEW_ADDRESS,
  SEND_CART_BUYS,
  SEND_FREIGHT_DETAILS,
  SEND_WISHLIST,
  GET_WISHLIST,
  SHOW_OPTIONS,
  SHOW_PRODUCTS,
  SHOW_SIZES,
  SHOW_SUCCESS_BUY,
  REGISTER_VO,
  GET_SLIDES,
  GET_PRODUCT,
  GET_ADDRESSES,
  GET_ADDRESS_VIA_CEP,
} from './type-actions';
import {
  DEFINE_LOGIN,
  DEFINE_OPTIONS,
  DEFINE_PRODUCTS,
  DEFINE_SIZES,
  DEFINE_SLIDES,
  DEFINE_SUCCESS_BUY,
  DEFINE_USER,
  NOTIFY,
  PIX_QR_CODE,
  DEFINE_PRODUCT,
  DEFINE_ADDRESSES
} from './type-mutations';
import { ISizes } from '@/interfaces/ISizes';
import { IOptionsProducts } from '@/interfaces/IOptionsProduct';
import { INotification } from '@/interfaces/INotification';
import axios from 'axios';
import { IQrCodePix } from '@/interfaces/IQrCodePix';
import { ISuccessBuy } from '@/interfaces/ISuccessBuy';
import { IUser } from '@/interfaces/IUser';
import { ILogin } from '@/interfaces/ILogin';
import { INewAddress } from '@/interfaces/INewAddress'


export interface State {
  products: IProducts[];
  product: any;
  sizes: ISizes[];
  productOptions: IOptionsProducts[];
  notifications: INotification[];
  pixQrCode: IQrCodePix;
  successBuy: ISuccessBuy;
  user: any;
  login: ILogin;
  slides: [];
  userAddresses: {endereco_cadastro: any, endereco_entrega: any[]};
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    user: {},
    login: {
      data: {
        adress: {
          id: 0,
          id_client: '',
          zip_code: '',
          neighborhood: '',
          number: '',
          state: '',
          street: '',
        },
        cpf_client: '',
        date_birth: '',
        email: '',
        id: '',
        loginVO: '',
        name_client: '',
        name_owner_corporate: '',
        phone: '',
        state_registrion: '',
        type_client: '',
      },
    },
    products: [],
    userAddresses: {endereco_cadastro: {}, endereco_entrega: []},
    product: {},
    sizes: [],
    productOptions: [],
    notifications: [],
    pixQrCode: {
      status: '',
      qrCode: '',
      price: 0,
      id: '',
      timer: -1,
      interval: 0,
    },
    successBuy: {
      type: '',
      text: '',
    },
    slides: [],
  },

  mutations: {
    [DEFINE_USER](state, user: any) {
      state.user = user;
    },
    [DEFINE_LOGIN](state, login: ILogin) {
      state.login = login;
    },
    [DEFINE_PRODUCTS](state, products: IProducts[]) {
      state.products = products;
    },
    [DEFINE_PRODUCT](state, product: IProducts) {
      state.product = product
    },
    [DEFINE_ADDRESSES](state, addresses: {endereco_cadastro: any, endereco_entrega: any[]}) {
      state.userAddresses = addresses
    },
    [DEFINE_SIZES](state, size: ISizes[]) {
      state.sizes = size;
    },
    [DEFINE_OPTIONS](state, option: IOptionsProducts[]) {
      state.productOptions = option;
    },
    [DEFINE_SUCCESS_BUY](state, message: ISuccessBuy) {
      state.successBuy = message;
    },
    [DEFINE_SLIDES](state, slide: []) {
      state.slides = slide;
    },
    [NOTIFY](state, newNotification) {
      if (state.notifications.length === 1) {
        state.notifications = [];

        setTimeout(() => {
          newNotification.id = new Date().getTime();

          state.notifications.push(newNotification);

          setTimeout(() => {
            state.notifications = state.notifications.filter(
              (notification) => notification.id != newNotification.id
            );
          }, 3000);
        }, 800);
      } else {
        state.notifications = [];

        newNotification.id = new Date().getTime();

        state.notifications.push(newNotification);

        setTimeout(() => {
          state.notifications = state.notifications.filter(
            (notification) => notification.id != newNotification.id
          );
        }, 3000);
      }
    },

    [PIX_QR_CODE](state, newQRCODE) {
 
      clearInterval(state.pixQrCode.interval);

      state.pixQrCode = newQRCODE;

      const interval = setInterval(clear, 1000);

      state.pixQrCode.interval = parseInt(interval.toString());

      function clear() {
        state.pixQrCode.timer--;
        if (state.pixQrCode.timer === -1) {
          clearInterval(interval);
        }
      }
    },
  },
  actions: {
    [SHOW_PRODUCTS]({ commit }) {
      http
        .get('api/product')
        .then((response) => response.data)
        .then((data) => commit(DEFINE_PRODUCTS, data.data))
        .catch((error: any) => console.log(error));
    },

    [SHOW_SIZES]({ commit }) {
      http
        .get('api/size')
        .then((response) => response.data)
        .then((data) => commit(DEFINE_SIZES, data.data))
        .catch((error: any) => console.log(error));
    },
    [SHOW_OPTIONS]({ commit }) {
      http
        .get('api/option-product')
        .then((response) => response.data)
        .then((data) => commit(DEFINE_OPTIONS, data.data))
        .catch((error: any) => console.log(error));
    },
    [SHOW_SUCCESS_BUY]({ commit }) {
      http
        .post('api/cart')
        .then((res) => res.data)
        .then((data) => commit(DEFINE_SUCCESS_BUY, data.data));
    },
    [SEND_FREIGHT_DETAILS](context, freight) { 
      return axios.post(
        'https://painel-hml.loja.enerzee.com.br/api/frete',
        freight
      );
    },
    [SEND_CART_BUYS](context, cart) {
      return http.post('api/cart', cart);
    },
    async [GET_USERS]({ commit }, login) {
      
      const res = await http.post('api/vo-user', {
        login: login,
      });
      const data = res.data;
      console.log('retorno ' ,data)
      localStorage.setItem('login', login);
      return commit(DEFINE_USER, data);
    },
    async [REGISTER_VO]({ commit }, vo) {
      const res = await http.get(`api/register/vo/${vo}`);
      const data = res.data;
      return commit(DEFINE_LOGIN, data);
    },

    async [GET_SLIDES]({ commit }) {
      const res = await http.get('api/slide');
      const data = res.data;
      return commit(DEFINE_SLIDES, data.data);
    },
    async [GET_PRODUCT]({ commit }, productId: string) {
      const res = await http.get(`api/product/${productId}`)
      const data = res.data
      return commit(DEFINE_PRODUCT, data.data)
    },
    async [GET_ADDRESSES]({commit}, userId: string) {
      const res = await http.get(`api/addressSend/${userId}`)
      const data = res.data.data
      return commit(DEFINE_ADDRESSES, data)
    },

    async [NEW_ADDRESS](context, addressInfo: INewAddress) {
      const post = await http.post(`api/addressSend`, addressInfo)
      return post
    },

    async [GET_ADDRESS_VIA_CEP]({commit}, cep: string) {
      const res = await http.get(`https://viacep.com.br/ws/${cep}/json/`)
      return res.data
    }
  },
});

export function useStore(): Store<State> {
  return vuexUseStore(key);
}
