export enum typesNotification {
    CART,
    ONCART,
    WISHLIST,
    ONWISHLIST,
    EMAILSEND,
}

export interface INotification {
    title: string,
    text: string,
    type: typesNotification,
    id: number
}