
import { computed, defineComponent } from 'vue';
import CartItems from './CartItems.vue';
import { useStore } from '@/store';
import { db } from '@/firebase';
import {
  collection,
  onSnapshot,
  query,
} from 'firebase/firestore';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'carrinhoEnerzee',
  components: {
    CartItems,
    Icon
  },
  props: ['fechar', 'show'],

  data() {
    return {
      cartItems: [
          {
            product_id: '',
            colorId: 0,
            color: 0,
            price: 0,
            product_option: 0,
            promotional_price: '',
            size: '',
          },
        ],
      firebase: process.env.VUE_APP_FIREBASE_STORE || 'CartItemsHomologacao',
    }
  },

  methods: {
    getCartProducts() {
      const q = query(collection(db, this.firebase + this.login.data.id));
      onSnapshot(q, (query) => {
        this.cartItems = [
          {
            product_id: '',
            colorId: 0,
            color: 0,
            price: 0,
            product_option: 0,
            promotional_price: '',
            size: '',
          },
        ];
        query.forEach((doc) => {
          this.cartItems.push(JSON.parse(JSON.stringify(doc.data())));
        });  
      });
    },

  },

  async mounted() {
    this.getCartProducts()
  },

  setup() {
    const store = useStore();

    return {
      login: computed(() => store.state.login),
    };
  },
});
