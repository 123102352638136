import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-end items-end pb-6 w-4/5" }
const _hoisted_2 = { class: "relative flex w-4/5 items-center" }
const _hoisted_3 = { class: "absolute end-0 bg-black h-14 p-4 focus:outline-none" }
const _hoisted_4 = { class: "truncate w-full text-lg hover:bg-gray-200 px-2 text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["z-50 fixed w-full h-content py-10 bg-white shadow transition-all duration-500 flex flex-col items-center justify-center", _ctx.show ? 'top-0' : '-top-full'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Icon, {
        icon: "teenyicons:x-solid",
        class: "text-cinza-padrao hover:cursor-pointer",
        onClick: _ctx.close
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        placeholder: "Buscar produtos",
        class: "w-full h-14 p-4 hover:border-2 border-black focus:outline-none focus:border-2 border-black",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
      }, null, 512), [
        [_vModelText, _ctx.filter]
      ]),
      _createElementVNode("button", _hoisted_3, [
        _createVNode(_component_Icon, {
          icon: "material-symbols:search",
          class: "text-white text-xl text-center"
        })
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["w-4/5 mt-4", _ctx.filter !== '' ? 'block' : 'hidden'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
        return (_openBlock(), _createBlock(_component_RouterLink, {
          class: "flex items-start w-full gap-2",
          key: product.id,
          to: `/products/${product.id}`,
          onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.close(); _ctx.filter = ''})
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(product.name_product), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ], 2)
  ], 2))
}