
import { computed, defineComponent } from 'vue';
import { Icon } from '@iconify/vue';
import ModalSearchBar from './ModalSearchBar.vue';
import Cart from './Cart.vue';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '@/firebase';
import { useStore } from '@/store';

export default defineComponent({
  name: 'HeaderEnerzee',
  components: {
    Icon,
    ModalSearchBar,
    Cart,
      },
  data() {
    return {
      modals: 'notShowing',
      changeColors: false,
      changeCounterCart: false,
      quantityCart: 0,
      imgEnv: process.env.VUE_APP_GET_IMAGES,
      urlImages: process.env.GET_IMAGES,
      firebaseCart:
        process.env.VUE_APP_FIREBASE_STORE || 'CartItemsHomologacao',
    };
  },

  methods: {
    closeModals() {
      this.modals = 'notShowing';
    },

    changeCartCounter() {
      onSnapshot(
        collection(db, this.firebaseCart + this.login.data.id),
        (snap) => {
          let itemsCart = [{}];

          snap.forEach((doc) => {
            itemsCart.push(doc.data());
          });

          this.quantityCart = itemsCart.slice(1).length;
        }
      );
    },
  },

  mounted() {
    this.changeCartCounter();
  },

  setup() {
    const store = useStore();
    return {
      login: computed(() => store.state.login),
    };
  },
});
