export const brazilianStates = [
        {
          acronym: 'AC',
          state: 'Acre',
        },
        {
          acronym: 'AL',
          state: 'Alagoas',
        },
        {
          acronym: 'AP',
          state: 'Amapá',
        },
        {
          acronym: 'AM',
          state: 'Amazonas',
        },
        {
          acronym: 'BA',
          state: 'Bahia',
        },
        {
          acronym: 'CE',
          state: 'Ceará',
        },
        {
          acronym: 'DF',
          state: 'Distrito federal',
        },
        {
          acronym: 'ES',
          state: 'Espiríto santo',
        },
        {
          acronym: 'GO',
          state: 'Goiás',
        },
        {
          acronym: 'MA',
          state: 'Maranhão',
        },
        {
          acronym: 'MT',
          state: 'Mato grosso',
        },
        {
          acronym: 'MS',
          state: 'Mato grosso do sul',
        },
        {
          acronym: 'MG',
          state: 'Minas gerais',
        },
        {
          acronym: 'PA',
          state: 'Pará',
        },
        {
          acronym: 'PB',
          state: 'Paraíba',
        },
        {
          acronym: 'PR',
          state: 'Paraná',
        },
        {
          acronym: 'PE',
          state: 'Pernambuco',
        },
        {
          acronym: 'PI',
          state: 'Piauí',
        },
        {
          acronym: 'RJ',
          state: 'Rio de janeiro',
        },
        {
          acronym: 'RN',
          state: 'Rio grande do norte',
        },
        {
          acronym: 'RS',
          state: 'Rio grande do sul',
        },
        {
          acronym: 'RO',
          state: 'Rondônia',
        },
        {
          acronym: 'RR',
          state: 'Roraima',
        },
        {
          acronym: 'SC',
          state: 'Santa catarina',
        },
        {
          acronym: 'SP',
          state: 'São paulo',
        },
        {
          acronym: 'SE',
          state: 'Sergipe',
        },
        {
          acronym: 'TO',
          state: 'Tocantins',
        },
      ]

