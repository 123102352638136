import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { key, store } from './store'
import './index.css';
import './http';
import { vMaska } from "maska";
import { firebaseApp } from './firebase';
import { VueFire, VueFireAuth } from 'vuefire'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App)
.directive("maska", vMaska)
.use(store, key)
.use(router)
.use(ElementPlus)
.use(VueFire, {
    firebaseApp,
    modules: [
        VueFireAuth(),
    ]
})
.mount('#app');