import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const firebaseApp = initializeApp({

  measurementId: 'G-W1S74MXZ0J',
  apiKey: "AIzaSyDj7rrcOQ4GSZGnWPn2xp-XHkGhjX92JdA",
  authDomain: "enerzee-ecommerce-fe0ce.firebaseapp.com",
  projectId: "enerzee-ecommerce-fe0ce",
  storageBucket: "enerzee-ecommerce-fe0ce.appspot.com",
  messagingSenderId: "818286079646",
  appId: "1:818286079646:web:187dc08f5da0667668d58f"
});

export const db = getFirestore(firebaseApp);
import { getStorage } from 'firebase/storage';
export const storage = getStorage(firebaseApp);

// here we can export reusable database references
// export const todosRef = collection(db, 'todos')
