export const SHOW_PRODUCTS = 'SHOW_PRODUCTS';
export const SHOW_SIZES = 'SHOW_SIZES';
export const SHOW_OPTIONS = 'SHOW_OPTIONS';
export const NEW_USER = 'NEW_USER';
export const SEND_FREIGHT_DETAILS = 'SEND_FREIGHT_DETAILS';
export const SEND_CART_BUYS = 'SEND_CART_BUYS';
export const SHOW_SUCCESS_BUY = 'SHOW_SUCCESS_BUY';
export const GET_USERS = 'GET_USERS';
export const SEND_WISHLIST = 'SEND_WISHLIST';
export const GET_WISHLIST = 'GET_WISHLIST';
export const REGISTER_VO = 'REGISTER_VO';
export const GET_SLIDES = 'GET_SLIDES';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_ADDRESSES = 'GET_ADDRESSES';
export const NEW_ADDRESS = 'NEW_ADDRESS';
export const GET_ADDRESS_VIA_CEP = 'GET_ADDRESS_VIA_CEP';
