
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { typesNotification } from "@/interfaces/INotification";
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: "NotificationEnerzee",

  components: {
		Icon,
	},

  data() {
    return {
      contextBg: {
        [typesNotification.CART]:
          "bg-verde-claro",
          [typesNotification.ONCART]:
          " bg-amarelo-notificacao",
        [typesNotification.WISHLIST]: 
         "bg-azul-claro",
         [typesNotification.ONWISHLIST]: 
         "bg-azul-escuro",
         [typesNotification.EMAILSEND]: 
         "bg-verde-escuro"
      },
      contextIcon: {
        [typesNotification.CART]:
         "mdi:cart-arrow-down",
         [typesNotification.WISHLIST]: 
         "mdi:heart-plus",
        [typesNotification.ONCART]:
        "mdi:cart-check",
        [typesNotification.ONWISHLIST]: 
         "mdi:cards-heart",
         [typesNotification.EMAILSEND]:
         "mdi:email-fast-outline",
      },
      contextIconColor: {
        [typesNotification.CART]:
          "text-verde-claro",
          [typesNotification.WISHLIST]:
          "text-azul-claro",
          [typesNotification.ONCART]:
          " text-amarelo-notificacao",
          [typesNotification.ONWISHLIST]: 
         "text-azul-escuro",
         [typesNotification.EMAILSEND]:
         "text-verde-escuro"
      }
    };
  },

  setup() {
    const store = useStore();

    return {
      notifications: computed(() => store.state.notifications),
    };
  },
});
