
import { PropType, computed, defineComponent, ref } from 'vue';
import { IProducts } from '@/interfaces/IProduct';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { IOptionsProducts } from '@/interfaces/IOptionsProduct';
import { useStore } from '@/store';
import { NOTIFY } from '@/store/type-mutations';
import { typesNotification } from '@/interfaces/INotification';
import { getProductSizes, sendToCart } from '@/helpers/commonFunctions';

export default defineComponent({
  name: 'ProductsEnerzee',

  props: {
    product: {
      type: Object as PropType<IProducts>,
      required: true,
    },
    options: {
      type: Object as PropType<IOptionsProducts[]>,
    },
  },


  data() {
    return {
      quickAddMessage: 'Comprar com 1 click',
      quickAdd: 'notShowing',
      photos: false,
      WishlistIcon: false,
      idsColor: 0,
      idColor: 0,
      optionProduct: 0,
      animationWishlist: false,
      position: 1,
      wishlistItems: [{}],
      env: process.env.VUE_APP_API_URL,
      firebaseCart:
        process.env.VUE_APP_FIREBASE_STORE || 'CartItemsHomologacao',
    };
  },

  methods: {
    async createProductCart(size: string) {
      const cartItem = sendToCart(this.checkedInputs, size, this.product);

      // sending then to firebase

      if (this.login.data.id != '') {
        const checkDoc = await getDoc(
          doc(
            db,
            this.firebaseCart + this.login.data.id,
            this.product.id +  this.checkedInputs + size
          )
        );
        if (checkDoc.exists()) {
          
            const getPrice = this.product.price_promotion && !isNaN(this.product.price_promotion)
            ? this.product.price_promotion : this.product.price

          let newQuantity = checkDoc.data()?.quantity + 1
          const maximumQuantity = 
              parseInt(this.product.option_product.filter((opt: any) => opt.id == checkDoc.data()?.product_option)[0].option.quantity)
        if (checkDoc.data()?.quantity >= maximumQuantity) {
          newQuantity = maximumQuantity
        }


          updateDoc(doc(db, this.firebaseCart + this.login.data.id, this.product.id +this.checkedInputs + size), {
            quantity: newQuantity,
            price: newQuantity * getPrice,

          })
          this.store.commit(NOTIFY, {
            title: this.product.name_product,
            text: `Produto adicionado ao carrinho!`,
            type: typesNotification.CART,
          });
        } else {
          setDoc(
            doc(
              db,
              this.firebaseCart + this.login.data.id,
              this.product.id +this.checkedInputs + size
            ),
            cartItem
          );
          // showing a notification
          this.store.commit(NOTIFY, {
            title: this.product.name_product,
            text: `Produto adicionado ao carrinho!`,
            type: typesNotification.CART,
          });
        }
      } else { 
        this.store.commit(NOTIFY, {
          title: 'LOGIN',
          text: `Faça login para adicionar produtos ao carrinho.`,
          type: typesNotification.CART,
        });
      }
    }, 
    handleMouseLeave() {
      this.quickAdd = 'notShowing';
      this.photos = false;
      this.WishlistIcon = false;
      // Atualize a posição aqui, se necessário, ou resete para o valor padrão
      this.position = 1; // Ou qualquer lógica para definir a posição
    },
    quickAddfunction(colorId: number) {
      let imagesArray = [{}];

      this.quickAdd = 'quickAdd';
      this.WishlistIcon = true;
      this.photos = false;

      for (var iii = 0; iii < this.product.img.length; iii++) {
        const images = this.product.img[iii];
        if (images.color.find((id: any) => id.id == colorId)) {
          imagesArray.push(JSON.parse(JSON.stringify(images)));
        }
      }
      if (imagesArray.slice(1).find((pst: any) => pst.position == 2)) {
        this.position = 2;
      } else {
        this.position = 1;
      }
    },
    ableSizes(idColor: number) {
      // allways set this to am empty array to not acumulate values using the push() function
      this.sizesOfProduct = [''];

      this.sizesOfProduct = getProductSizes(idColor, this.product);
    },
  },

  async mounted() {
    this.finalProductPrice = this.product.price; 

    let colors = this.product.color.map((colors) =>
      colors.attributes.map((cc) => cc.id)
    );

    this.checkedInputs = parseInt(colors.toString());
    await this.ableSizes(parseInt(colors.toString()));

    sendToCart(this.checkedInputs, 'PP', this.product);
  },

  setup() {
    const checkedInputs = ref();
    const sizesStore = ref();
    const sizesOfProduct = ref(['']);
    const colorOfTheSize = ref(['']);
    const store = useStore();
    const finalProductPrice = ref(0);
    return {
      checkedInputs,
      sizesStore,
      sizesOfProduct,
      colorOfTheSize,
      store,
      finalProductPrice,
      login: computed(() => store.state.login),
    };
  },
});
